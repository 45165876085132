
import { Scenario } from '@/models';
import { ApiService, LoaderService } from '@/services';
import { onMounted, defineComponent, ref } from 'vue';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import Editor from 'primevue/editor';
import { useRouter } from 'vue-router';
import { CategoryScenariosRoute } from '@/router';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';

export default defineComponent({
	components: {
		Textarea,
		Button,
		Editor
	},
	props: {
		categoryId: String,
		scenarioId: String
	},
	setup(props) {
		const router = useRouter();
		const scenario = ref<Scenario>(null);
		const loadToken = LoaderService.invoke();
		const toast = useToast();
		const confirm = useConfirm();

		onMounted(async () => {
			if (!props.scenarioId) {
				scenario.value = {
					text: '',
					resources: null,
					categoryId: parseInt(props.categoryId as string)
				};

				LoaderService.clear(loadToken);
				return;
			}

			try {
				scenario.value = await ApiService.get(`/scenarios/${props.scenarioId}`);
			} catch (e) {
				toast.add({
					severity: 'error',
					summary: 'Error Occurred',
					detail: 'Unable to load scenario. Try again later.',
					life: 3000
				});
			} finally {
				LoaderService.clear(loadToken);
			}
		});

		const submit = async () => {
			const submitLoadToken = LoaderService.invoke();

			try {
				if (props.scenarioId) {
					await ApiService.put(
						`/scenarios/${props.scenarioId}`,
						scenario.value
					);
				} else {
					await ApiService.post(`/scenarios`, scenario.value);
				}
				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: 'Scenario saved.',
					life: 3000
				});
				router.push({
					name: CategoryScenariosRoute,
					params: {
						categoryId: scenario.value.categoryId
					}
				});
			} catch (e) {
				toast.add({
					severity: 'error',
					summary: 'Error Occurred',
					detail: 'Unable to save scenario. Try again later.',
					life: 3000
				});
			} finally {
				LoaderService.clear(submitLoadToken);
			}
		};

		const deleteScenario = async () => {
			confirm.require({
				header: 'Are you sure?',
				message: 'Are you sure you want to delete this scenario?',
				icon: 'pi pi-exclamation-triangle',
				accept: async () => {
					await performDelete();
				},
				acceptLabel: 'Delete',
				acceptClass: 'p-button-danger',
				rejectLabel: 'I change my mind'
			});
		};

		const performDelete = async () => {
			const deleteLoadToken = LoaderService.invoke();

			try {
				if (!props.scenarioId) {
					return;
				}
				await ApiService.remove(
					`/scenarios/${props.scenarioId}`,
					scenario.value
				);

				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: 'Scenario deleted.',
					life: 3000
				});

				router.push({
					name: CategoryScenariosRoute,
					params: {
						categoryId: scenario.value.categoryId
					}
				});
			} catch (e) {
				toast.add({
					severity: 'error',
					summary: 'Error Occurred',
					detail: 'Unable to delete scenario. Try again later.',
					life: 3000
				});
			} finally {
				LoaderService.clear(deleteLoadToken);
			}
		};

		return { scenario, submit, deleteScenario };
	}
});
